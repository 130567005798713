import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Services from "../services/services";
import { Modal, Button, Row, Col, Table, Select } from 'antd';
import 'antd/dist/antd.css';
import useCollapse from 'react-collapsed';
import 'font-awesome/css/font-awesome.min.css';
import { IdleTimerProvider } from 'react-idle-timer';
import { ReloadOutlined, CloseOutlined, LeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';


import "./Lift.css";


const { Option } = Select;

const initialState = {
    terminals: [],
    eqPhotos: [],
    filteredEqPhotos: [],
    currentUserTerminal: null,
    loading: true,
};

const filterInitialState = {
    terminal: "ALL",
    search: "",
}

const modalInitialState = {
    visible: false,
    photo: "",
    video: "",
    fileName: "",
    isVideo: false
}

export const EquipmentPhoto = () => {
    const [state, setState] = useState(initialState);
    const [filterState, setFilterState] = useState(filterInitialState);
    const [modalState, setModalState] = useState(modalInitialState);
    const [firstLoad, setFirstLoad] = useState(true);

    const { equip } = useParams();
    const [loadedEquip, setLoadedEquip] = useState(equip?.toUpperCase());

    const [isExpanded, setExpanded] = useState(true)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

    const onIdleTimerProviderPrompt = () => {
    }

    const onIdleTimerProviderActive = (event) => {
    }

    const onIdleTimerProviderAction = (event) => {
    }

    const onIdleTimerProviderIdle = () => {
        handleReload()
    }

    const handleModalExit = () => {
        setModalState(modalInitialState);
    }

    const getFileName = (path) => {
        return path.split('/').pop();
    };

    const downloadMedia = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName.replace(/\.jpg$/i, '.jpeg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleModalDownload = () => {
        const url = modalState.isVideo ? modalState.video : modalState.photo;
        downloadMedia(url, modalState.fileName);
    };

    const handleReload = () => {
        var newUrl = loadedEquip ? `/EquipmentPhoto/${(loadedEquip ?? "").toUpperCase()}` : "/EquipmentPhoto";
        window.location.href = newUrl;
    };


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            responsive: ['lg'],
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'Picture',
            dataIndex: 'photo',
            key: 'photo',
            align: 'center',
            render: (text, record) => (
                <div>
                    {record.photo && (
                        <img
                            src={`data:image/png;base64,${record.photo}`}
                            alt="captured-img"
                            style={{ width: '44px', height: '44px', cursor: 'pointer' }}
                            onClick={() => handleMediaClick(record)}
                        />
                    )}
                    {record.video && (
                        <video
                            src={`data:video/mp4;base64,${record.video}`}
                            style={{ width: '44px', height: '44px', cursor: 'pointer' }}
                            onClick={() => handleMediaClick(record)}
                        />
                    )}
                </div>
            )
        },
        {
            title: 'Equipment',
            dataIndex: 'equipNum',
            key: 'equipNum',
            align: 'center',
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'Terminal',
            dataIndex: 'terminal',
            key: 'terminal',
            align: 'center',
            render: (text, record) => <span className="fs-6 fw-bold">{record.offsite ? "Off-site" : text}</span>
        },
        {
            title: 'Line',
            dataIndex: 'lineCode',
            key: 'lineCode',
            align: 'center',
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            align: 'center',
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'Off-site comment',
            dataIndex: 'offsiteComment',
            key: 'offsiteComment',
            align: 'center',
            responsive: ['md'],
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'Date',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            align: 'center',
            responsive: ['lg'],
            render: (text) => <span className="fs-6 fw-bold">{moment(text).format("YYYY-MM-DD HH:mm")}</span>
        },
        {
            title: 'User',
            dataIndex: 'userCode',
            key: 'userCode',
            align: 'center',
            responsive: ['lg'],
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'Life#',
            dataIndex: 'lifeNum',
            key: 'lifeNum',
            align: 'center',
            responsive: ['lg'],
            render: (text) => <span className="fs-6 fw-bold">{text}</span>
        },
        {
            title: 'CONFIRMED',
            dataIndex: 'confirmed',
            key: 'confirmed',
            align: 'center',
            responsive: ['lg'],
            render: (text, record) => <span className="fs-6 fw-bold">{text ? "Yes" : "No"}</span>
        },
    ];

    const renderEqPhotoTable = () => {
        return (
            <>
                <IdleTimerProvider
                    timeout={1000 * 60}
                    onPrompt={onIdleTimerProviderPrompt}
                    onIdle={onIdleTimerProviderIdle}
                    onActive={onIdleTimerProviderActive}
                    onAction={onIdleTimerProviderAction} />

                <div className="collapsible">
                    <div className="header" {...getToggleProps({
                        onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                    })} >
                        <div className="border border-5 border-secondary bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                            <Row gutter={[8, 8]} type="flex" justify="space-around" align="middle">
                                <Col xs={0} sm={0} md={0} lg={4} style={{ color: "white", fontWeight: "bold" }} className="fs-5">
                                    <i className={isExpanded ? 'fa fa-minus' : 'fa fa-plus'}></i>Eq. Photos
                                </Col>
                                <Col xs={{ span: 1, order: 0 }} sm={{ span: 1, order: 0 }} md={{ span: 1, order: 0 }} lg={0} style={{ color: "white", fontWeight: "bold" }} className="fs-5" align="middle">
                                    <i className={isExpanded ? 'fa fa-minus' : 'fa fa-plus'}></i>
                                </Col>
                                <Col xs={{ span: 15, order: 3, offset: 1 }} sm={{ span: 15, order: 3, offset: 1 }} md={{ span: 15, order: 3, offset: 1 }} lg={{ span: 6, order: 1, offset: 0 }} align="middle">
                                    <Form.Control
                                        type="text"
                                        name="search"
                                        value={filterState.search}
                                        placeholder="Search the grid..."
                                        onClick={(i) => {
                                            i.preventDefault();
                                            i.stopPropagation();
                                        }}
                                        onChange={(i) => {
                                            handleFilters(i, "search");
                                            i.preventDefault();
                                            i.stopPropagation();
                                        }}
                                    />
                                </Col>
                                <Col xs={{ span: 15, order: 1 }} sm={{ span: 15, order: 1 }} md={{ span: 15, order: 1 }} lg={{ span: 6, order: 2 }} align="middle">
                                    <Form.Control
                                        style={{ textTransform: 'uppercase' }}
                                        type="text"
                                        name="equip"
                                        value={loadedEquip}
                                        placeholder="Current equip..."
                                        onClick={(i) => {
                                            i.preventDefault();
                                            i.stopPropagation();
                                        }}
                                        onChange={(i) => {
                                            setLoadedEquip(i.target.value?.toUpperCase());
                                            i.preventDefault();
                                            i.stopPropagation();
                                        }}
                                    />
                                </Col>
                                <Col xs={{ span: 8, order: 2 }} sm={{ span: 8, order: 2 }} md={{ span: 8, order: 2 }} lg={{ span: 4, order: 3 }} align="middle">
                                    <Button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontSize: '11pt', fontWeight: 'bold' }}
                                        type="primary"
                                        className="rounded"
                                        icon={<ReloadOutlined />}
                                        onClick={(i) => {
                                            i.preventDefault();
                                            i.stopPropagation();
                                            handleReload()
                                        }}>
                                        Reload
                                    </Button>
                                </Col>
                                <Col xs={{ span: 8, order: 4 }} sm={{ span: 8, order: 4 }} md={{ span: 8, order: 4 }} lg={{ span: 4, order: 4 }} align="middle">
                                    <Link style={{ color: 'white', textDecoration: 'none' }} disabled={equip ? false : true} to={equip ? `/EquipmentQuery/${equip}` : "/EquipmentQuery"}>
                                        <Button className="rounded" type="primary" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontSize: '11pt', fontWeight: 'bold' }} icon={<LeftOutlined />}>
                                            Back to query
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div {...getCollapseProps()}>
                        <div className="content">
                            {
                                !state.loading &&
                                <div style={{ paddingTop: '0px' }}>
                                    <div className="border border-5 border-secondary  overflow bg-secondary">
                                        <table>
                                            <tbody>
                                                <Table
                                                    dataSource={state.filteredEqPhotos}
                                                    columns={columns}
                                                    rowClassName={(record) => record.offsite ? 'table-colored-row' : ''}
                                                    onRow={(record) => ({ onClick: () => handleMediaClick(record) })}
                                                    pagination={false}
                                                />
                                            </tbody>
                                            <tfoot>
                                                <Row gutter={[8, 8]} type="flex" justify="space-around" align="middle" className="border border-5 border-secondary">
                                                    <Col span={3}>
                                                        <Form.Label style={{ fontWeight: 'bold' }}>Terminal</Form.Label>
                                                    </Col>
                                                    <Col span={9}>
                                                        <Form.Select value={filterState.terminal} inline="true" className="fs-7 terminal-select" size="sm" onChange={i => handleFilters(i, "terminal")}>
                                                            <option value="ALL">All Terminals</option>
                                                            {state.terminals.map((terminal) => (<option value={terminal}>{terminal}</option>))}
                                                        </Form.Select>
                                                    </Col>
                                                    <Col span={12}>
                                                    </Col>
                                                </Row>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                state.loading === true &&
                                <div className="border border-5 border-secondary  overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                                    <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                                </div>
                            }
                        </div>
                    </div>
                </div >

                <Modal
                    style={{ textTransform: 'uppercase', fontSize: '3em' }}
                    width="min(90%, 640px)"
                    height="min(90%, 480px)"
                    onCancel={handleModalExit}
                    visible={modalState.visible}
                    bodyStyle={{ height: 'calc(100% - 110px)', overflow: 'hidden' }}
                    footer={
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button
                                style={{ display: 'inline-flex', alignItems: 'center', width: '8em' }}
                                key="download"
                                type="primary"
                                onClick={handleModalDownload}
                                shape="round"
                                icon={<DownloadOutlined />}
                            >
                                Download
                            </Button>
                            <Button
                                style={{ display: 'inline-flex', alignItems: 'center', width: '6em' }}
                                key="exit"
                                type="danger"
                                onClick={handleModalExit}
                                shape="round"
                                icon={<CloseOutlined />}
                            >
                                Exit
                            </Button>
                        </div>
                    }
                    destroyOnClose={true}
                    closable={false}>
                    <Form layout="vertical">
                        <Row justify="center" style={{ height: '100%' }}>
                            <div style={{ border: 'solid', borderWidth: '1px', height: '100%', width: '100%', textAlign: 'center', overflow: 'hidden' }}>
                                {modalState.isVideo ? (
                                    <video
                                        controls
                                        src={modalState.video}
                                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                    />
                                ) : (
                                    <img
                                        id="imgPhoto"
                                        src={modalState.photo}
                                        alt="captured-img"
                                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                    />
                                )}
                            </div>
                            <div style={{ width: '100%', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                {modalState.isVideo ? 'Equipment video' : 'Equipment picture'}
                            </div>
                        </Row>
                    </Form>
                </Modal>

            </>
        );
    };


    const updateFilterState = (filter, value) => {
        setFilterState({
            ...filterState,
            [filter]: value
        });
    };

    const handleFilters = (e, filter) => {
        updateFilterState(filter, e.target.value);
    };


    const handleMediaClick = (record) => {
        setModalState({
            visible: true,
            fileName: getFileName(record.imagePath),
            photo: record.photo ? `data:image/png;base64,${record.photo}` : "",
            video: record.video ? `data:video/mp4;base64,${record.video}` : "",
            isVideo: !!record.video
        });
    };

    const render = () => {
        let contents = renderEqPhotoTable();
        return <div>{contents}</div>;
    };

    const filterData = (item, filter) => {
        if (!filter?.trim())
            return true;

        if (item.equipNum && item.equipNum.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.tag && item.tag.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.lifeNum && item.lifeNum.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.dateCreated && moment(item.dateCreated).format("YYYY-MM-DD HH:mm").toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.offsiteComment && item.offsiteComment.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.location && item.location.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.lineCode && item.lineCode.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.terminal && item.terminal.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.id && item.id.toString().includes(filter.toUpperCase()))
            return true;

        return false;
    };

    async function populateEqPhotoData() {
        Services.getEqPhotos(loadedEquip?.toUpperCase(), (response) => {
            setState({
                eqPhotos: response.data.eqPhotos,
                filteredEqPhotos: response.data.eqPhotos.filter(i => loadedEquip === null || loadedEquip === "" || i.equipNum === loadedEquip),
                terminals: response.data.terminals,
                currentUserTerminal: response.data.currentUserTerminal,
                loading: false,
            });
        });
    }

    useEffect(() => {
        if (state.eqPhotos.length > 0) {
            if (firstLoad) {
                setFirstLoad(false);
                if (state.currentUserTerminal !== null && state.currentUserTerminal !== "") {
                    updateFilterState("terminal", state.currentUserTerminal);
                }
            } else {
                updateFilterState();
            }
        }
    }, [state.eqPhotos]);

    useEffect(() => {
        if (firstLoad) {
            populateEqPhotoData();
        } else {
            let filtered = state.eqPhotos;
            if (filterState?.search?.trim())
                filtered = state.eqPhotos.filter((i) => filterData(i, filterState.search));

            if (filterState.terminal !== "ALL") {
                if (filterState.terminal == null || filterState.terminal === "")
                    filtered = filtered.filter(i => !i.terminal)
                else
                    filtered = filtered.filter(i => i.terminal === filterState.terminal);
            }
            if (state.eqPhotos[0]) {
                setState({
                    ...state,
                    filteredEqPhotos: filtered,
                });
            }
        }
    }, [filterState]);

    return render();
};
