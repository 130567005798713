import React, { useState, useCallback, useMemo, useEffect } from "react";
import Services from "../services/services";
import GeneralServices from "../services/generalServices";
import { Modal, Form, Row, Col, Input, Button, Checkbox, Typography } from 'antd';
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';
import "./Lift.css";
import { SearchOutlined, FolderOutlined, CameraOutlined, ReloadOutlined, SaveOutlined, UploadOutlined, CloseOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import ImageCapture from 'react-image-data-capture';
import FloatLabel from "./tools/FloatLabel";
import GenericDropdown from "./tools/GenericDropdown";
import { Link, useParams, useHistory } from 'react-router-dom';


const { TextArea } = Input;

const initialQueryResult = {
    cntr_num: "",
    validCheckDigit: false,
    size: "",
    type: "",
    height: "",
    line: "",
    rs_ta_terminal: "",
    location: "",
    year: "",
    status: "",
    bdjob: "",
    bdStatus: "",
    damJob: "",
    damStatus: "",
    bdComment: "",
    maintComment: "",
    lastPtiComment: "",
    lastPtiDate: "",
    lastPtiType: "",
    ptiType: "",
    isoCode: "",
    onHold: "",
    commentOH: "",
    outOfService: "",
    bodyDamage: "",
    color: "",
    isOut: false,
    daysIn: 0,
    inMaint: false,
    inFleet: false,
    equipNotes: "",
    eqPhotoModel: {}
}

const modalInitialState = {
    visible: false,
    takingPhotoOrPreview: true,
    isValid: false,
    photos: [],
    videos: [],
    photoType: "",
    currentEquipNum: null,
    endedWithSuccessfulSave: false
}

export const EquipmentQuery = () => {
    const [qres, setQres] = useState(initialQueryResult);
    //const [hasLocation, setHasLocation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sizeTypeHeight, setSizeTypeHeight] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [modalState, setModalState] = useState(modalInitialState);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalFailure, setShowModalFailure] = useState(false);
    const [modalWait, setModalWait] = useState(false);

    const { equip } = useParams()
    const history = useHistory()

    const handleSearch = (equipNumber) => {
        if (equip && equip !== equipNumber) {
            history.push(`/EquipmentQuery/${equipNumber}`);
        } else {
            setLoading(true);
            Services.getEquipmentQuery(equipNumber, (response) => {
                setLoading(false);

                if (response && response != null && response.data) {
                    setQres(response.data);
                    var message = "";
                    if (!response.data.validCheckDigit) {
                        message += "Warning...! Invalid check digit.\n\n";
                    }

                    if (response.data.cntr_num?.trim() && (!response.data.inMaint || response.data.isOut) && (!response.data.eqPhotoModel || response.data.eqPhotoModel.id <= 0)) {
                        message += "Attention: This equipment is OFF-SITE and lacks photos. Please add at least one photo to save this record. ISO code, line, and site are required.";
                    }

                    if (message) {
                        window.alert(message);
                    }

                    setSizeTypeHeight((response.data.size?.trim() ? response.data.size?.trim() : "") + (response.data.type?.trim() ? response.data.type?.trim() : "") + (response.data.height?.trim() ? response.data.height?.trim() : ""));
                }
                else {
                    setQres(initialQueryResult);
                    setSizeTypeHeight("");
                }
            });
        }
    };

    useEffect(() => {
        if (equip) {
            setSearchValue(equip);
            handleSearch(equip);
        }
    }, [equip]);

    useEffect(() => {
        if (qres?.cntr_num?.trim() && (!qres.inMaint || qres.isOut)) {
            setQres(prevQres => ({ ...prevQres, eqPhotoModel: { ...prevQres.eqPhotoModel, offsite: true } }));
        }
    }, [qres?.cntr_num]);

    const onError = useCallback((error) => { console.log(error) }, []);

    const validateBeforeSave = () => {
        var message = "";

        if (!qres.inMaint && !qres.inFleet) {
            var line = qres?.eqPhotoModel?.lineCode?.trim();
            var iso = qres?.eqPhotoModel?.isoCode?.trim();

            if (!line)
                message += "You must choose a line.\n";
            if (!iso)
                message += "You must choose an ISO code.\n";
        }

        if (!qres.inMaint || qres.isOut) {
            var site = qres?.eqPhotoModel?.offsiteLocation?.trim();
            var offsiteChecked = qres?.eqPhotoModel?.offsite;

            if (!site)
                message += "You must specify the offsite location.\n";
            if (!offsiteChecked)
                message += "You must check the offsite box.\n";
        }

        if (message !== "") {
            window.alert(message);
            return false;
        }

        return true;
    }

    const handleSave = () => {

        if (!validateBeforeSave()) {
            return;
        }

        if (!window.confirm('Are you sure?')) {
            return;
        }

        Services.saveEquipmentPhotoInfo(qres.cntr_num, {
            id: qres.eqPhotoModel.id,
            line: qres.eqPhotoModel.lineCode,
            site: qres.eqPhotoModel.offsiteLocation,
            terminal: qres.eqPhotoModel.terminal,
            location: qres.eqPhotoModel.location,
            isoCode: qres.eqPhotoModel.isoCode,
            offsite: qres.eqPhotoModel.offsite,
            offsiteComment: qres.eqPhotoModel.offsiteComment,
            confirmed: qres.eqPhotoModel.confirmed
        }, (response) => {
            if (response && response != null && response.data) {
                window.alert("Information saved successfully.")
            }
            else {
                window.alert("Error in saving information...!")
            }
        });
    }

    const handleModalSave = () => {
        setModalWait(true);
        if (!validateBeforeSave()) {
            setModalWait(false);
            return;
        }

        const formData = new FormData();
        modalState.photos.forEach((photo, index) => {
            formData.append(`photos`, photo.split(',')[1]);
        });
        modalState.videos.forEach((video, index) => {
            formData.append(`videos`, video.split(',')[1]);
        });

        formData.append('line', qres?.eqPhotoModel?.lineCode ?? "");
        formData.append('site', qres?.eqPhotoModel?.offsiteLocation ?? "");
        formData.append('terminal', qres?.eqPhotoModel?.terminal ?? "");
        formData.append('location', qres?.eqPhotoModel?.location ?? "");
        formData.append('isoCode', qres?.eqPhotoModel?.isoCode ?? "");
        formData.append('offsite', qres?.eqPhotoModel?.offsite ?? false);
        formData.append('offsiteComment', qres?.eqPhotoModel?.offsiteComment ?? "");
        formData.append('confirmed', qres?.eqPhotoModel?.confirmed ?? false);

        Services.saveEquipmentMedia(modalState.currentEquipNum, formData, (response) => {
            if (response && response != null && response.data) {
                setModalWait(false);
                setShowModalSuccess(true);
                setModalState({ ...modalState, endedWithSuccessfulSave: true })
                setTimeout(() => {
                    setShowModalSuccess(false);
                }, 5000);
            }
            else {
                setModalWait(false);
                setShowModalFailure(true);
                setTimeout(() => {
                    setShowModalFailure(false);
                }, 5000);
            }
        });
    };

    const handleModalExit = () => {
        var currentEquip = modalState.currentEquipNum
        var endedWithSuccessfulSave = modalState.endedWithSuccessfulSave
        if (currentEquip && endedWithSuccessfulSave) {
            handleSearch(currentEquip);
        }
        setModalState(modalInitialState);
    }

    const handleMediaCapture = () => {
        setModalState({ ...modalState, visible: true, currentEquipNum: qres.cntr_num });
    }

    const handleReset = () => {
        setModalState({ ...modalState, isValid: false, photos: [], videos: [], takingPhotoOrPreview: true });
    }

    const handleFileUpload = (event) => {
        var files = event.target.files;
        if (files) {
            var promises = [];
            for (var index = 0; index < files.length; index++) {
                promises.push(toBase64(files[index]));
            }
            Promise.all(promises).then(results => {
                var base64Files = [];
                var base64Videos = [];
                results.forEach(data => {
                    if (data && data !== null && data !== "") {
                        if (data.startsWith('data:image')) {
                            base64Files.push(data);
                        } else if (data.startsWith('data:video') || data.startsWith('data:application')) {
                            base64Videos.push(data);
                        }
                    }
                });
                setModalState({
                    ...modalState,
                    isValid: base64Files.length > 0 || base64Videos.length > 0,
                    photos: base64Files,
                    videos: base64Videos,
                    takingPhotoOrPreview: false
                });
            }).catch(error => {
                console.error("Error converting files to base64:", error);
            });
        }
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const base64ToBlobUrl = (base64) => {
        const mimeType = base64.split(',')[0].match(/:(.*?);/)[1];

        const byteCharacters = atob(base64.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: mimeType });
        return URL.createObjectURL(blob);
    }

    const onCapture = (imageData) => {
        setModalState({
            ...modalState, isValid: imageData && imageData.webP && imageData.webP !== null && imageData.webP !== ""
            , photos: [imageData.webP], videos: [], takingPhotoOrPreview: false
        });
    };

    const config = useMemo(() => ({
        video: !isMobile ? true : {
            facingMode: {
                exact: "environment"
            }
        }
    }), []);

    const getBorderStyle = (condition) => {
        if (condition) {
            return { borderColor: 'lightgreen', borderStyle: 'double' };
        } else {
            return { borderColor: null, borderStyle: null };
        }
    };

    const getStatusColor = (status) => {
        var color = "Grey";

        if (!status)
            return color;

        if (status.includes("AVAILABLE") || status.includes("LEASE IN"))
            color = "Green";
        else if (status.includes("TO BE P"))
            color = "Blue";
        else if (status.includes("END OF CYCLE") || status.includes("LEASE OUT") || status.includes("OUT ON LEASE"))
            color = "YellowGreen";
        else if (status.includes("OUT OF DEPOT"))
            color = "Violet";
        else if (status.includes("MECH. DAMAGE") || status.includes("MECH/DAMAGED"))
            color = "Red";
        else if (status.includes("BODY DAMAGE"))
            color = "Magenta";

        return color;
    }

    return (
        <>
            <div className="border border-5 border-secondary bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                <Row gutter={[8, 8]} type="flex" justify="space-around" align="middle">
                    <Col xs={{ span: 15, offset: 1 }} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} lg={8}>
                        <Input className="uppercase-input"
                            onChange={e => {
                                const cleanedValue = e.target.value.replace(/\s+/g, '');
                                setSearchValue(cleanedValue);
                            }}
                            value={searchValue}
                            onKeyPress={i => {
                                if (i.key === "Enter") {
                                    handleSearch(searchValue);
                                    i.preventDefault();
                                    i.stopPropagation();
                                }
                            }}
                            onClick={i => {
                                i.preventDefault();
                                i.stopPropagation();
                            }}
                            placeholder="Equipment number to search..." prefix={<SearchOutlined />} />
                    </Col>
                    <Col xs={8} sm={6} md={4} lg={8}>
                        <Button style={{ display: 'inline-flex', alignItems: 'center' }} className="rounded"
                            onClick={
                                i => {
                                    handleSearch(searchValue);
                                    i.preventDefault();
                                    i.stopPropagation();
                                }}
                            icon={<SearchOutlined />}
                            type="primary">Search</Button>
                    </Col>
                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 6, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 4, offset: 4 }} >
                        <Checkbox disabled={(qres?.cntr_num?.trim() && (qres.isOut || !qres.inMaint)) ? false : true}
                            checked={qres?.cntr_num?.trim() && qres.eqPhotoModel?.offsite} className="huge-checkbox"
                            onChange={(e) => setQres({ ...qres, eqPhotoModel: { ...qres.eqPhotoModel, offsite: e.target.checked } })}>
                            <span style={{ fontSize: '15pt', marginLeft: '0.5em', color: "white" }}>Off-site?</span>
                        </Checkbox>
                    </Col>
                </Row>
            </div>

            <div className="content">
                <div style={{ paddingTop: '0px' }}>
                    {
                        loading === false &&
                        <div className="border border-5 border-secondary overflow">
                            <Form layout="vertical" style={{ width: '98%', margin: '0 auto', marginTop: '1em' }}>
                                <Row gutter={[8, 8]} type="flex" justify="space-around" align="middle">

                                    <Col xs={0} sm={0} md={0} lg={{ span: 4, order: 0 }}>
                                        <Form.Item name="equipNum" style={{ marginBottom: 0 }}>
                                            <Typography.Text name="equipNum" style={{ fontSize: '22px', fontWeight: 'bold', textTransform: "uppercase" }}>
                                                {qres?.cntr_num?.trim() ? qres.cntr_num : "Equip num."}
                                            </Typography.Text>
                                        </Form.Item>
                                    </Col>

                                    {
                                        qres?.cntr_num?.trim() && ((!qres.isOut && qres.daysIn > 0) || qres.ptiType?.trim()) ?
                                            (
                                                <Col
                                                    xs={{ span: 24, order: 1 }}
                                                    sm={{ span: 24, order: 1 }}
                                                    md={{ span: 12, order: 1 }}
                                                    lg={{ span: 4, order: 1 }}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        height: '100%'
                                                    }}
                                                >
                                                    {!qres.isOut && qres.daysIn > 0 && (
                                                        <Row>
                                                            <Form.Item name="daysIn" style={{ marginBottom: 0 }}>
                                                                <Typography.Text
                                                                    style={{
                                                                        fontSize: '18px',
                                                                        color: 'red',
                                                                        fontWeight: 'bold',
                                                                        textTransform: 'uppercase'
                                                                    }}
                                                                >
                                                                    Days IN: {qres.daysIn}
                                                                </Typography.Text>
                                                            </Form.Item>
                                                        </Row>
                                                    )}
                                                    {qres.ptiType?.trim() && (
                                                        <Row>
                                                            <Form.Item name="ptiType" style={{ marginBottom: 0 }}>
                                                                <Typography.Text
                                                                    style={{
                                                                        fontSize: '18px',
                                                                        color: 'blue',
                                                                        fontWeight: 'bold',
                                                                        textTransform: 'uppercase'
                                                                    }}
                                                                >
                                                                    {qres.ptiType.trim()}
                                                                </Typography.Text>
                                                            </Form.Item>
                                                        </Row>
                                                    )}
                                                </Col>
                                            ) :
                                            <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 4, order: 1 }}>
                                            </Col>
                                    }

                                    <Col xs={{ span: 12, order: 10 }} sm={{ span: 12, order: 10 }} md={{ span: 12, order: 10 }} lg={{ span: 4, order: 2 }}>
                                        <Form.Item name="bdDamJob">
                                            <FloatLabel label="Bd. dam. job" name="bdDamJob">
                                                <Input value={qres.bdjob} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 12, order: 11 }} sm={{ span: 12, order: 11 }} md={{ span: 12, order: 11 }} lg={{ span: 4, order: 3 }}>
                                        <Form.Item name="bdDamJobStatus">
                                            <FloatLabel label="Bd. dam. job status" name="bdDamJobStatus">
                                                <Input value={qres.bdStatus} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 8, order: 3 }} sm={{ span: 8, order: 3 }} md={{ span: 8, order: 3 }} lg={{ span: 3, order: 4 }}>
                                        <Form.Item name="line" style={getBorderStyle(qres?.cntr_num?.trim() && (!qres.inMaint || qres.isOut))}>
                                            <FloatLabel label="Line"
                                                name="line"
                                                required={qres?.cntr_num?.trim() && !qres.inMaint && qres.inFleet ? 'required' : null}>
                                                <GenericDropdown
                                                    fetchDataFunction={GeneralServices.getShippingLines}
                                                    //param1={qres.cntr_num} // Additional parameters as needed
                                                    labelProperty="label"
                                                    keyProperty="key"
                                                    isDisabled={!qres?.cntr_num?.trim() || (qres.inMaint && !qres.isOut)}
                                                    onSelect={(selectedValue) => setQres({ ...qres, eqPhotoModel: { ...qres.eqPhotoModel, lineCode: selectedValue } })}
                                                    value={qres.eqPhotoModel.lineCode}
                                                />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 8, order: 4 }} sm={{ span: 8, order: 4 }} md={{ span: 8, order: 4 }} lg={{ span: 2, order: 5 }}>
                                        <Form.Item name="terminal">
                                            <FloatLabel label="Terminal" name="terminal">
                                                <Input value={qres.eqPhotoModel.terminal ?? ""}
                                                    onChange={t => setQres({ ...qres, eqPhotoModel: { ...qres.eqPhotoModel, terminal: t.target.value } })}
                                                    readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 8, order: 5 }} sm={{ span: 8, order: 5 }} md={{ span: 8, order: 5 }} lg={{ span: 3, order: 6 }}>
                                        <Form.Item name="location" className="uppercase-input" style={getBorderStyle(qres?.cntr_num?.trim() && qres.inMaint && !qres.isOut /*&& !hasLocation*/)}>
                                            <FloatLabel label="Location" name="location">
                                                <Input maxLength={10}
                                                    readOnly={qres?.cntr_num?.trim() && qres.inMaint && !qres.isOut /*&& !hasLocation*/ ? false : true}
                                                    value={qres.eqPhotoModel.location ?? ""}
                                                    onChange={t => setQres({ ...qres, eqPhotoModel: { ...qres.eqPhotoModel, location: t.target.value } })} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>

                                    {/************************************************************************************************************************/}

                                    <Col xs={{ span: 24, order: 0 }} sm={{ span: 24, order: 0 }} md={{ span: 12, order: 0 }} lg={{ span: 8, order: 7 }}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            height: '100%'
                                        }}>
                                        <Form.Item name="status" style={{ marginBottom: 0 }}>
                                            <Typography.Text name="status" style={{ fontSize: '22px', color: getStatusColor(qres.status), fontWeight: 'bold' }}>
                                                {qres?.status?.trim()
                                                    ? qres.status.replace("BODY DAMAGE", "").replace("ON HOLD", "").trim()
                                                    : "<Status>"}
                                            </Typography.Text>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 12, order: 12 }} sm={{ span: 12, order: 12 }} md={{ span: 12, order: 12 }} lg={{ span: 4, order: 8 }}>
                                        <Form.Item name="repairJob">
                                            <FloatLabel label="Repair job" name="repairJob">
                                                <Input value={qres.damJob} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 12, order: 13 }} sm={{ span: 12, order: 13 }} md={{ span: 12, order: 13 }} lg={{ span: 4, order: 9 }}>
                                        <Form.Item name="repairJobStatus">
                                            <FloatLabel label="Repair job status" name="repairJobStatus">
                                                <Input value={qres.damStatus} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 8, order: 6 }} sm={{ span: 8, order: 6 }} md={{ span: 8, order: 6 }} lg={{ span: 3, order: 10 }}>
                                        {
                                            qres?.cntr_num?.trim() && qres.inMaint && !qres.eqPhotoModel?.isoCode ?
                                                (<>
                                                    <Form.Item name="sizeTypeHeight">
                                                        <FloatLabel label="Size/Type/Height" name="sizeTypeHeight">
                                                            <Input value={sizeTypeHeight} readOnly={true} />
                                                        </FloatLabel>
                                                    </Form.Item>
                                                </>) :
                                                (<>
                                                    <Form.Item name="isoCode" style={getBorderStyle(qres?.cntr_num?.trim() && !qres.inMaint/* && qres.inFleet*/)}>
                                                        <FloatLabel label="ISO code"
                                                            name="isoCode"
                                                            required={qres?.cntr_num?.trim() && !qres.inMaint /*&& qres.inFleet*/ ? 'required' : null}>
                                                            <GenericDropdown
                                                                fetchDataFunction={GeneralServices.getIsoCodes}
                                                                labelProperty="label"
                                                                isDisabled={qres?.cntr_num?.trim() && !qres.inMaint /*&& qres.inFleet*/ ? false : true}
                                                                keyProperty="key"
                                                                onSelect={(selectedValue) => setQres({ ...qres, eqPhotoModel: { ...qres.eqPhotoModel, isoCode: selectedValue } })}
                                                                value={qres.eqPhotoModel.isoCode ?? ""}
                                                            />
                                                        </FloatLabel>
                                                    </Form.Item>
                                                </>)
                                        }
                                    </Col>
                                    <Col xs={{ span: 8, order: 7 }} sm={{ span: 8, order: 7 }} md={{ span: 8, order: 7 }} lg={{ span: 2, order: 11 }}>
                                        <Form.Item name="year">
                                            <FloatLabel label="Year" name="year">
                                                <Input value={qres.year} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 8, order: 8 }} sm={{ span: 8, order: 8 }} md={{ span: 8, order: 8 }} lg={{ span: 3, order: 12 }}>
                                        <Form.Item name="site" className="uppercase-input" style={getBorderStyle(qres?.cntr_num?.trim() && (!qres.inMaint || qres.isOut))}>
                                            <FloatLabel label="Site" name="site"
                                                required={qres?.cntr_num?.trim() && (!qres.inMaint || qres.isOut) ? 'required' : null}>
                                                <Input
                                                    readOnly={qres?.cntr_num?.trim() && (!qres.inMaint || qres.isOut) ? false : true}
                                                    value={qres.eqPhotoModel.offsiteLocation ?? ""}
                                                    onChange={t => setQres({ ...qres, eqPhotoModel: { ...qres.eqPhotoModel, offsiteLocation: t.target.value } })} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>

                                    {/************************************************************************************************************************/}

                                    {
                                        (qres?.cntr_num?.trim() && (qres.onHold || qres.outOfService || qres.bodyDamage)) &&
                                        <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} lg={{ span: 24, order: 13 }}>
                                            <Form.Item name="onHold">
                                                <Typography.Text name="onHold" style={{ fontSize: '22px', color: 'red', fontWeight: 'bold' }}>
                                                    {qres?.outOfService?.trim() ? qres.outOfService : ""}
                                                    {(qres.onHold || qres.bodyDamage) && qres.outOfService ? " - " : ""}
                                                    {qres?.onHold?.trim() ? qres.onHold + (qres?.commentOH?.trim() ? " - " + qres.commentOH : "") : ""}
                                                    {qres.onHold && qres.bodyDamage ? " - " : ""}
                                                    {qres?.bodyDamage?.trim() ? qres.bodyDamage : ""}
                                                </Typography.Text>
                                            </Form.Item>
                                        </Col>
                                    }

                                    {/************************************************************************************************************************/}

                                    <Col xs={{ span: 24, order: 14 }} sm={{ span: 24, order: 14 }} md={{ span: 24, order: 14 }} lg={{ span: 16, order: 14 }}>
                                        <Form.Item name="equipment">
                                            <FloatLabel label="Equipment comment" name="equipment">
                                                <Input value={qres.maintComment} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, order: 9 }} sm={{ span: 24, order: 9 }} md={{ span: 24, order: 9 }} lg={{ span: 8, order: 15 }}>
                                        <Form.Item name="offsiteComment">
                                            <FloatLabel label="Offsite comment" name="offsiteComment">
                                                <Input
                                                    readOnly={(qres?.cntr_num?.trim() && (qres.isOut || !qres.inMaint)) ? false : true}
                                                    value={qres.eqPhotoModel.offsiteComment ?? ""}
                                                    onChange={t => setQres({ ...qres, eqPhotoModel: { ...qres.eqPhotoModel, offsiteComment: t.target.value } })} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>

                                    {/************************************************************************************************************************/}

                                    <Col xs={{ span: 24, order: 20 }} sm={{ span: 24, order: 20 }} md={{ span: 24, order: 20 }} lg={{ span: 16, order: 16 }}>
                                        <Form.Item name="notes">
                                            <FloatLabel label="Notes" name="notes">
                                                <TextArea rows={2} value={qres.equipNotes} style={{ fontSize: '18px' }} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 12, order: 21 }} sm={{ span: 12, order: 21 }} md={{ span: 12, order: 21 }} lg={{ span: 8, order: 17 }} align="middle">
                                        <Form.Item>
                                            <Link style={{ color: qres?.cntr_num?.trim() ? 'white' : 'lightgray', textDecoration: 'none' }} to={`/EquipmentPhoto/${qres.cntr_num}`}>
                                                <Button
                                                    disabled={!qres?.cntr_num?.trim()}
                                                    className="rounded"
                                                    type="primary"
                                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', fontSize: '15pt', fontWeight: 'bold' }}
                                                    icon={<FolderOutlined />}>
                                                    View media
                                                </Button>
                                            </Link>
                                        </Form.Item>
                                    </Col>

                                    {/************************************************************************************************************************/}

                                    <Col xs={{ span: 8, order: 15 }} sm={{ span: 8, order: 15 }} md={{ span: 8, order: 15 }} lg={{ span: 6, order: 18 }}>
                                        <Form.Item name="lastPti">
                                            <FloatLabel label="Last PTI" name="lastPti">
                                                <Input value={(qres?.lastPtiDate?.trim() ?? "") + " / " + (qres?.lastPtiType?.trim() ?? "")} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 16, order: 16 }} sm={{ span: 16, order: 16 }} md={{ span: 16, order: 16 }} lg={{ span: 10, order: 19 }}>
                                        <Form.Item name="lastPtiComment">
                                            <FloatLabel label="Last PTI comment" name="lastPtiComment">
                                                <Input value={qres.lastPtiComment} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={{ span: 12, order: 22 }} sm={{ span: 12, order: 22 }} md={{ span: 12, order: 22 }} lg={{ span: 8, order: 20 }} align="middle">
                                        <Form.Item>
                                            <Button onClick={handleMediaCapture} style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', fontSize: '15pt', fontWeight: 'bold' }} disabled={qres?.cntr_num?.trim() ? false : true} className="rounded" icon={<CameraOutlined />} type="primary">Capture media</Button>
                                        </Form.Item>
                                    </Col>

                                    {/************************************************************************************************************************/}

                                    <Col xs={{ span: 24, order: 19 }} sm={{ span: 24, order: 19 }} md={{ span: 24, order: 19 }} lg={{ span: 16, order: 21 }}>
                                        <Form.Item name="bodyDamage">
                                            <FloatLabel label="Body damage info" name="bodyDamage">
                                                <Input value={qres.bdComment} readOnly={true} />
                                            </FloatLabel>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={{ span: 12, order: 23 }} sm={{ span: 12, order: 23 }} md={{ span: 12, order: 23 }} lg={{ span: 8, order: 22 }} align="middle" >
                                        <Form.Item >
                                            <Button onClick={handleSave} style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', fontSize: '15pt', fontWeight: 'bold' }} disabled={qres?.cntr_num?.trim() && ((qres?.eqPhotoModel && qres?.eqPhotoModel?.id && qres.eqPhotoModel.id > 0) || (qres.inMaint && !qres.isOut)) ? false : true} className="rounded" icon={<SaveOutlined />} type="primary">Save info</Button>
                                        </Form.Item>
                                    </Col>

                                    {/************************************************************************************************************************/}

                                </Row>

                            </Form>
                        </div>
                    }
                    {
                        loading === true &&
                        <div className="border border-5 border-secondary overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                            <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                        </div>
                    }
                </div>
            </div >


            <Modal
                style={{ textTransform: 'uppercase', fontSize: '3em' }}
                onCancel={handleModalExit}
                width="min(90%, 640px)"
                height="min(90%, 580px)"
                visible={modalState.visible}
                bodyStyle={{ height: 'calc(100% - 110px)', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
                footer={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <Button style={{ display: 'inline-flex', alignItems: 'center', width: '13em' }} icon={<SaveOutlined />}
                                key="save" disabled={modalWait === true} type="primary" onClick={handleModalSave} shape="round">
                                Save current media
                            </Button>
                            <Button style={{ display: 'inline-flex', alignItems: 'center', width: '13em' }} icon={<UploadOutlined />}
                                key="load" disabled={modalWait === true} type="primary" shape="round">
                                <label htmlFor="fileInput" style={{ marginLeft: '0.6em' }}>
                                    Load from gallery
                                </label>
                            </Button>
                            <input
                                type="file"
                                id="fileInput"
                                accept="image/*, video/*,.hevc,.3gp"
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                                multiple
                            />
                        </div>
                        <div>
                            <Button style={{ display: 'inline-flex', alignItems: 'center', width: '6em' }}
                                onClick={handleReset}
                                key="reset" shape="round" icon={<ReloadOutlined />}>
                                Reset
                            </Button>
                            <Button style={{ display: 'inline-flex', alignItems: 'center', width: '6em' }}
                                key="exit" type="danger" onClick={handleModalExit} shape="round" icon={<CloseOutlined />}>
                                Exit
                            </Button>
                        </div>
                        <div style={{ position: 'absolute', transition: 'opacity 1s ease-in-out' }}>
                            {showModalSuccess && <p style={{ fontSize: '9pt', color: 'green' }}>Success! Media and info saved.</p>}
                            {showModalFailure && <p style={{ fontSize: '9pt', color: 'red' }}>Error: Unable to save media and info.</p>}
                        </div>
                    </div>
                }
                destroyOnClose={true}
                closable={false}>
                <div style={{ position: 'relative', flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{
                        filter: modalWait ? 'blur(3px)' : 'none',
                        opacity: modalWait ? 0.5 : 1,
                        transition: 'filter 0.3s, opacity 0.3s',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Form layout="vertical" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            {modalState.takingPhotoOrPreview ? (
                                <Row justify="center" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                    <div style={{
                                        border: 'solid',
                                        borderWidth: '1px',
                                        width: '100%',
                                        paddingTop: '75%', // 4:3 aspect ratio
                                        position: 'relative',
                                        overflow: 'hidden'
                                    }}>
                                        <div style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <ImageCapture
                                                onCapture={onCapture}
                                                onError={onError}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                    maxWidth: '437px',
                                                    maxHeight: '328px'
                                                }}
                                                className='imgCapture'
                                                userMediaConfig={config}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                        Equipment
                                    </div>
                                </Row>
                            ) : (
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                    <Row justify="center" style={{ flex: 1 }}>
                                        <div style={{ border: 'solid', borderWidth: '1px', flex: 1, width: '100%', textAlign: 'center', overflow: 'hidden' }}>
                                            {modalState.photos.length > 0 ? (
                                                <img id="imgPhoto" src={modalState.photos[0]} alt="captured-img" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            ) : modalState.videos.length > 0 ? (
                                                <video id="videoPreview" src={base64ToBlobUrl(modalState.videos[0])} controls style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            ) : (
                                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    No media selected
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ width: '100%', fontWeight: 'bold', fontSize: '12px' }} className="btn" role="button">
                                            Equipment preview
                                        </div>
                                    </Row>
                                    {(modalState.photos.length > 1 || modalState.videos.length > 0) && (
                                        <Row justify="center" style={{ marginTop: '10px', maxHeight: '90px', overflow: 'hidden' }}>
                                            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                                                {modalState.photos.slice(1).map((photo, index) => (
                                                    <div key={`photo-${index}`} style={{ display: 'inline-block', margin: '0 5px' }}>
                                                        <img src={photo} alt={`thumbnail-${index}`} style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                                                    </div>
                                                ))}
                                                {modalState.videos.map((video, index) => (
                                                    <div key={`video-${index}`} style={{ display: 'inline-block', margin: '0 5px', position: 'relative' }}>
                                                        <video src={base64ToBlobUrl(video)} style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', padding: '2px 5px', borderRadius: '3px' }}>
                                                            ▶
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Row>
                                    )}
                                </div>
                            )}
                        </Form>
                    </div>
                    {modalWait && (
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '32px',
                            color: 'black',
                        }}>
                            <i className="fa fa-spinner fa-spin"></i>
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
};
